import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";

import HomePage from "./pages/HomePage";
import { FilterContext } from "./context/FilterContext";

function App() {
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [filters, setFilters] = useState([]);
  const [zoom, setZoom] = useState([]);

  useEffect(() => {
    ReactGA.initialize([{ trackingId: "G-TET4F8SR8R" }]);
    ReactGA.send({ hitType: "pageview", page: "/" });
  }, []);

  return (
    <div className="App">
      <FilterContext.Provider
        value={{
          filters,
          setFilters,
          appliedFilters,
          setAppliedFilters,
          zoom,
          setZoom,
        }}
      >
        <HomePage />
      </FilterContext.Provider>
    </div>
  );
}

export default App;
